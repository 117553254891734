import * as $ from "jquery";
import { initTuPopover } from "./popover";

let tumarket = window.tumarket;

/** @type {Object.<string, boolean>} */
let readmoreState = tumarket.readmoreState;

/** @type {Object.<string, (e: JQuery<HTMLElement>) => void>} */
let callbacks = tumarket.callbacks;

/**
 * 
 * @param {JQuery<HTMLElement>} el 
 * @param {boolean} [value]
 * @param {boolean} [skipCallback]
 */
function toggleReadmore(el, value = null, skipCallback = null){
    let $wrap = $(el).closest(".tum-readmore-wrap");
    let $toggle = $(el);
    let $target = $(el).attr("data-target");
    let $texts = null;
    let $otherToggles = null;
    let id = $wrap.attr('data-readmoreid');
    let groupContainer = $(el)[0].dataset.groupContainer;

    if (groupContainer && !skipCallback) {
        [...document.querySelector(groupContainer).querySelectorAll(".tum-readmore-toggle")]
            .filter(e => e != this)
            .forEach(e => toggleReadmore(e, false, true));
    }

    if ($target) {
        $texts = $($target);
        $otherToggles = $('.tum-readmore-toggle[data-target="' + $target + '"]').not($(el));
    } else {
        $texts = $(".tum-readmore-0, .tum-readmore-1, .tum-readmore-2, .tum-readmore-inline", $wrap);
        $otherToggles = $(".tum-readmore-toggle", $wrap).not($(el));
    }

    function swapToggleHtml($toggle, show){
        let showHtml = $toggle.attr('data-showhtmltarget') ? $($toggle.attr('data-showhtmltarget')).html() : $toggle.attr('data-showhtml');
        let hideHtml = $toggle.attr('data-hidehtmltarget') ? $($toggle.attr('data-hidehtmltarget')).html() : $toggle.attr('data-hidehtml');

        let html = show ? hideHtml : showHtml;
        if (html != "_") {
            $toggle.html(html);
        }
    }

    $texts.each(function(){
        let $text = $(this);
        let _wrap = $(this).closest(".tum-readmore-wrap");

        if (_wrap.length && !_wrap.is($wrap)) {
            return;
        }

        $text.toggleClass("show", value);
        let show = $text.hasClass("show");
        swapToggleHtml($toggle, show);
        $otherToggles.each(function(){
            let _toggleWrap = $(this).closest(".tum-readmore-wrap");
            if (_toggleWrap.is($wrap) || !_toggleWrap.length) swapToggleHtml($(this), show); 
        });

        let ajaxCode = $text.attr('data-ajaxcode');
        let ajaxOptions = $text.attr('data-ajaxoptions');
        let ajaxLoaded = $text.data('ajaxloaded') == 1;
        if (ajaxCode && !ajaxLoaded) {
            import("./ajaxComponent").then(({ renderComponent }) => {
                renderComponent(ajaxCode, ajaxOptions)
                .then(html => { 
                    $text.html(html)
                    $text.data('ajaxloaded', 1);
                    initTuPopover($text);
                    document.body.dispatchEvent(new Event("tum.component.loaded"));
                });
            });
        }

        if (id) {
            readmoreState[id] = show;
        }
    });

    let callback = $(el).attr('data-callback');
    if (!skipCallback && callback && callbacks && callbacks[callback]) {
        callbacks[callback](el);
    }
}

function initReadmore(){
    $(document).on("click", ".tum-readmore-toggle", function (e) {
        //если кликнуть на toggle, который находится внутри другого toggle, будет двойной вызов и в итоге ничего не отработает
        //но stopPropagation может вызвать проблемы в другом функционале, например сборе статистики при кликах
        //поэтому вместо этого явно проверяем, что ридмор уже обработан
        if (e.result?.readmore_processed) return;

        if (!this.matches('[data-dont-prevent-default]')) {
            e.preventDefault();
        }
        
        let readmoreState = null;
        if (this.dataset.readmoreState) {
            readmoreState = this.dataset.readmoreState == 'show';
        }

        toggleReadmore($(this), readmoreState);
        
        return { readmore_processed: true };
    });

    $(document).on('shown.bs.collapse', function(e){
        let id = $(e.target).attr('data-readmoreid');
        readmoreState[id] = true;
    });

    $(document).on('hidden.bs.collapse', function(e){
        let id = $(e.target).attr('data-readmoreid');
        readmoreState[id] = false;
    });
}

/**
 * простой ручной toggle для collapse без анимации. Можно использовать для сохранения состояния коллапсов при ререндере
 * @param {JQuery<HTMLElement>} element 
 * @param {boolean} [value] 
 * @param {JQuery<HTMLElement>} [container] 
 */
function toggleCollapse(element, value = null, container = null){
    let $collapseToggle = $(container || document).find(element);
    let $collapse = $($collapseToggle.attr('data-target'), container || document);

    let showIcons = [ '.fa-arrow-down', '.fa-chevron-down' ];
    let hideIcons = [ '.fa-arrow-up', '.fa-chevron-up' ];
    let $icon = $([showIcons, hideIcons].flatMap(e => e).join(', '), $collapseToggle);
    $icon.toggleClass(showIcons.join(', '), !value).toggleClass(hideIcons.join(', '), value);

    $collapseToggle.toggleClass('collapsed', !value);
    $collapse.toggleClass('show', value);

    let id = $collapse.attr('data-readmoreid');
    if (id && value !== null) readmoreState[id] = value;
}

/**
 * 
 * @param {JQuery<HTMLElement>} container 
 */
function restoreReadmoreState(container) {
    for (let id in readmoreState){
        let $readmoreToggle = $(`.tum-readmore-wrap[data-readmoreid=${id}] .tum-readmore-toggle`, container).first();
        if ($readmoreToggle.length)	toggleReadmore($readmoreToggle, readmoreState[id]);

        let $collapse = $(`[data-toggle="collapse"][data-readmoreid="${id}"]`, container);
        if ($collapse.length) toggleCollapse($collapse, readmoreState[id], container);
    }
}

/**
 * 
 * @param {string} id 
 * @param {boolean} [value] 
 * @returns 
 */
function toggleReadmoreById(id, value = null) {
    let $element = $(`[data-readmoreid="${id}"]`);
    if ($element.hasClass('tum-readmore-wrap')) {
        let $toggle = $('.tum-readmore-toggle', $element).first();
        toggleReadmore($toggle, value);
        
        return;
    }

    if ($element.is('[data-toggle=collapse]')) {
        toggleCollapse($element, value);
    }
}

window.toggleReadmore = toggleReadmore;

export { initReadmore, toggleReadmore, restoreReadmoreState, toggleReadmoreById };